import React from "react";
import styled from "styled-components";
import { SocialIcon } from "react-social-icons";

const WrapperDiv = styled.div`
  display: inline-block;
`;
const WrapperSpan = styled.span`
  margin: 0px 20px 0 5px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const WrapperSocialMedia = styled(SocialIcon)`
  background-image: none;
  @media (max-width: 768px) {
    margin: 0px 5px;
  }
`;
export default ({ url, label }) => {
  return (
    <WrapperDiv>
      <WrapperSocialMedia url={url} label={label} bgColor="black" />
      <WrapperSpan>{label}</WrapperSpan>
    </WrapperDiv>
  );
};
