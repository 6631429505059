import React from "react";
import styled from "styled-components";

const WrapperDiv = styled.div`
  display: inline-block;
  width: 38px;
  height: 38px;
  &:after {
    content: " ";
    display: block;
    width: 30px;
    height: 30px;
    margin: 8px;
    border-radius: 50%;
    border: 1px solid black;
    border-color: black transparent black transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default () => {
  return <WrapperDiv />;
};
