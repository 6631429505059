import React, { useState } from "react";
import styled, { css } from "styled-components";
import LoadingBar from "../LoadingBar/LoadingBar";

const WrapperForm = styled.form`
	margin: 50px auto;
}
`;

const SharedInputStyle = css`
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	outline: none;
	display: block;
	width: 100%;
	padding: 7px;
	border: none;
	border-bottom: 1px solid black;
	background: transparent;
	margin-bottom: 10px;
	height: 45px;
}
`;

const WrapperFormInput = styled.input`
  ${SharedInputStyle}
`;

const WrapperFormInputArea = styled.input`
  ${SharedInputStyle}
  resize:none;
  overflow: hidden;
`;

const WrapperButton = styled.button`
  background-color: white;
  border: 1px solid black;
  display: inline-block;
  cursor: pointer;
  color: black;
  font-size: 14px;
  padding: 8px 18px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    background-color: goldenrod;
    border-color: goldenrod;
    color: white;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const WrapperDiv = styled.div`
  text-align: center;
`;

const WrapperSuccess = styled.p`
  text-align: center;
  color: green;
`;

const WrapperError = styled.p`
  text-align: center;
  color: red;
`;
const submitForm = (ev, setState) => {
  setState("LOADING");
  ev.preventDefault();
  const form = ev.target;
  const data = new FormData(form);
  const xhr = new XMLHttpRequest();
  xhr.open(form.method, form.action);
  xhr.setRequestHeader("Accept", "application/json");
  xhr.onreadystatechange = () => {
    if (xhr.readyState !== XMLHttpRequest.DONE) return;
    if (xhr.status === 200) {
      form.reset();
      setState("SUCCESS");
    } else {
      setState("ERROR");
    }
  };
  xhr.send(data);
};
export default () => {
  const [submitStatus, setSubmitStatus] = useState("");
  return (
    <WrapperForm
      action="https://formspree.io/mgenpyjw"
      method="POST"
      onSubmit={ev => submitForm(ev, setSubmitStatus)}
    >
      <WrapperFormInput type="text" name="name" placeholder="Name" />
      <WrapperFormInput type="email" name="_replyto" placeholder="Email" />
      <WrapperFormInputArea
        name="message"
        placeholder="Message"
      ></WrapperFormInputArea>
      {submitStatus === "LOADING" && (
        <WrapperDiv>
          <LoadingBar />
        </WrapperDiv>
      )}
      {(submitStatus === "" || submitStatus === "ERROR") && (
        <WrapperButton>Submit</WrapperButton>
      )}
      {submitStatus === "SUCCESS" && <WrapperSuccess>Thanks!</WrapperSuccess>}

      {submitStatus === "ERROR" && (
        <WrapperError>Ooops! There was an error.</WrapperError>
      )}
    </WrapperForm>
  );
};
