import React from "react";
import styled from "styled-components";
import SocialMediaIcon from "../components/SocialMediaIcon/SocialMediaIcon.js";
import ContentBlock from "../components/Content/ContentBlock";
import ContactForm from "../components/ContactForm/ContactForm";
import PageTitle from "../components/PageTitle/PageTitle";

const WrapperDiv = styled.div`
  @media (max-width: 768px) {
    text-align: center;
  }
`;
export default () => {
  return (
    <div>
      <PageTitle title="Contact" url="http://alexdhaenens.com/about" />
      <ContentBlock title="Contact me directly">
        <ContactForm />
      </ContentBlock>
      <ContentBlock title="Find me on social media">
        <p>Find more about me on following social media:</p>
        <WrapperDiv>
          <SocialMediaIcon
            url="https://www.linkedin.com/in/alex-dhaenens-54742b148/"
            label="linkedIn"
          />
          <SocialMediaIcon
            url="https://twitter.com/dhaenensalex"
            label="twitter"
          />
          <SocialMediaIcon url="https://dev.to/alexdhaenens" label="dev.to" />
          <SocialMediaIcon
            url="https://github.com/AlexDhaenens"
            label="github"
          />
        </WrapperDiv>
      </ContentBlock>
    </div>
  );
};
